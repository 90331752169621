import React, { useEffect, useState } from 'react'


const Courses = () => {

  return (
    <div className="container">
      <div id="section-courses-content" className="py-4 my-5">

        <h1 className="simil-header">Job offers</h1>
        <div className="d-flex">
          <ul className="list-inline mx-auto justify-content-center">
            <li>No active positions at the moment, come back later!</li>
          </ul>
        </div>
        <div className="simil-header">
          Planckian
        </div>
        <p className="text-center">Check out our startup on quantum batteries: <a href="https://www.planckian.co/">planckian</a></p>
        <div className="simil-header">
          SNS Quantum Information courses
        </div>
        <div className="container">
          <div className="d-flex">
            <ul className="list-inline mx-auto justify-content-center">
              <li>
                <a href="https://www.sns.it/it/corsoinsegnamento/quantum-information-theory">Quantum Information Theory</a>
              </li>
              <li>
                <a href="https://www.sns.it/it/corsoinsegnamento/information-methods-quantum-technologies">Information Methods for Quantum Technologies</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Courses
